import React from 'react';
import './home.css';
import Navbar from '../Navbar/navbar';
import audit from '../../img/audit-icon.png'
import Footer from '../Footer/footer';



const Home =() => {
  return (
    <>
    <Navbar />
    <div className="content">
      <section class="combsec">
        <div class="header-section">
          <div class="navpad">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3 col-3 rightone1">
<img src={audit}></img>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-2 col-2 rightone1'></div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-7 leftone">
                  <div class="center-div">
                    <h1 class="font-weight-bold">
                      AUDIT <span> &</span> Assurances
                    </h1>
                    <p>
                      Financial statement audits give assurance over information used by investors and the
                      capital markets.
                    </p>
                    <div class="homeline1"></div>
                    <h3>Independent, quality financial<br></br> statement audits</h3>
                    <div class="header-buttons">
                      {/* Removed the login buttons from here */}
                    </div>
                  </div>
                </div>
                {/* <div class="col-lg-2 col-md-2 col-sm-2 col-2 rightone1">
                  <div className="row blackboxcontainer">
                    <div class="blackbox1"></div>
                    <div class="blackbox2"></div>
                    <div class="blackbox3"></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
<hr/>
      <section class="work_flow" id="workflow">
        <div class="container justify-content-center">
          <div class="work_head">
            <h5>How we work</h5>
            <div class="homeline3"></div>
            <h1>Our Business Workflow</h1>
          </div>

          <div class="row">

            <div class="col-lg-3 col-md-3 col-12 ">
              <div class="circle1">
                <h4>01</h4>
              </div>
              <h3>Selection</h3>
            </div>

            <div class="col-lg-3 col-md-3 col-12">
              <div class="circle1">
                <h4>02</h4>
              </div>
              <h3>Planing</h3>
            </div>

            <div class="col-lg-3 col-md-3 col-12">
              <div class="circle1">
                <h4>03</h4>
              </div>
              <h3>Fieldwork</h3>
            </div>

            <div class="col-lg-3 col-md-3 col-12">
              <div class="circle1">
                <h4>04</h4>
              </div>
              <h3>Review & Reports</h3>
            </div>
          </div>
        </div>

      </section>

     
    </div>
<Footer />
    </>
    
  );
}

export default Home;
