import React, { useEffect } from "react";
import { Route } from "react-router";
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import Home from "./components/Home/home";
import Certificate from "./components/Certificate/certificate"; 
import UploadPDF from "./components/UploadDoc/UploadPdf";
import Admin from "./components/Admin/Admin";
import Navbar from "./components/Navbar/navbar";
import Footer from "./components/Footer/footer";
import ServiceNow from "./components/certserviceNow/serviceNow";

const App = () => {


  return (
    <>
    
      <Route exact path="/">
        <Home />
      </Route>

      <Route path="/certificate/EarthID">
        <Certificate />
      </Route>

      <Route path="/certificate/ServiceNow">
        <ServiceNow />
      </Route>

      <Route path="/uploadDoc">
        <UploadPDF />
      </Route>

      <Route path="/admin">
        <Admin />
      </Route>

    </>
  );
};

export default App;
