import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import './uploadPdf.css';
import Navbar from '../Navbar/navbar';
import Footer from '../Footer/footer';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentMetadata = () => {
  const [files, setFiles] = useState([]);
  const [numPages, setNumPages] = useState([]);
  const [pdfMetaData, setPdfMetaData] = useState([]);
  const [showMainContent, setShowMainContent] = useState(false); // State variable to track tab selection
  const history = useHistory();

  const handleViewCertificate = () => {
    // Perform any necessary actions before redirecting

    // Redirect to the desired component
    window.open('/certificate/EarthID', '_blank');
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleFileUpload = async () => {
    if (files.length === 0) {
      // Display a message asking to add a document first
      Swal.fire({
        icon: 'warning',
        title: 'Add Document',
        text: 'Please add a document first.',
      });
      return;
    }

    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const arrayBuffer = reader.result;
        const pdf = await pdfjs.getDocument(arrayBuffer).promise;

        const numPages = pdf.numPages;
        setNumPages((prevNumPages) => [...prevNumPages, numPages]);

        const metadata = await pdf.getMetadata();
        const uniqueId = generateUniqueId();
        const formattedDateTime = formatDateTime(metadata.info.ModDate);

        const documentMetadata = {
          ...metadata.info,
          uniqueId,
          formattedDateTime,
        };

        saveMetadataToLocal(documentMetadata, file);
      };
      reader.readAsArrayBuffer(file);
    }
    setShowMainContent(true); // Set showMainContent to true after file upload

    // Display success message after file upload
    Swal.fire({
      icon: 'success',
      title: 'Successful',
      text: 'The document has been submitted successfully.',
    });
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const formatDateTime = (dateTime) => {
    const dateRegex = /D:(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/;
    const [, year, month, day, hour, minute, second] = dateRegex.exec(dateTime);

    const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    return formattedDateTime;
  };

  const saveMetadataToLocal = async (metadata, file) => {
    const storedMetadata = localStorage.getItem('pdfMetaData');
    let allMetadata = storedMetadata ? JSON.parse(storedMetadata) : [];
    allMetadata.push(metadata);
    localStorage.setItem('pdfMetaData', JSON.stringify(allMetadata));
    console.log(allMetadata);

    setPdfMetaData((prevPdfMetaData) => [...prevPdfMetaData, metadata]);
  };

  return (
    <>
      <Navbar />
      <div className="user-dashboard">
        <div className="sidebar">
          <ul>
            <li>
              <button onClick={() => setShowMainContent(true)}>Add Document</button>
            </li>
            <li>
              <button onClick={handleViewCertificate}>View Certificate</button>
            </li>
          </ul>
        </div>
        <div className="main-content">
          {showMainContent ? (
            <>
              <input type="file" onChange={handleFileChange} multiple />
              <button
                onClick={handleFileUpload}
                disabled={files.length === 0}
                className={files.length === 0 ? 'disabled' : ''}
              >
                Upload files
              </button>
              {pdfMetaData.map((metadata, index) => (
                <div key={index}>
                  <h3>Document Metadata {index + 1}:</h3>
                  <p>Unique ID: {metadata.uniqueId}</p>
                  <p>Creation Time: {metadata.formattedDateTime}</p>
                  <pre>{JSON.stringify(metadata, null, 2)}</pre>
                  {files[index] && (
                    <div>
                      <h3>Document Preview {index + 1}:</h3>
                      <Document file={files[index]}>
                        <Page pageNumber={1} />
                      </Document>
                    </div>
                  )}
                </div>
              ))}
              {numPages.map((pages, index) => (
                <p key={index}>Number of pages for Document {index + 1}: {pages}</p>
              ))}
            </>
          ) : (
            <div className="blank-div">Click on "Add Document" in the sidebar to upload documents.</div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DocumentMetadata;


// import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import { useHistory } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import './uploadPdf.css';
// import Navbar from '../Navbar/navbar';
// import Footer from '../Footer/footer';

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const DocumentMetadata = () => {
//   const [files, setFiles] = useState([]);
//   const [numPages, setNumPages] = useState([]);
//   const [pdfMetaData, setPdfMetaData] = useState([]);
//   const [showMainContent, setShowMainContent] = useState(false); // State variable to track tab selection
//   const history = useHistory();

//   const handleViewCertificate = () => {
//     // Perform any necessary actions before redirecting
    
//     // Redirect to the desired component
//     window.open('/certificate/EarthID', '_blank');
//   };

//   const handleFileChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     setFiles(selectedFiles);
//   };

//   const handleFileUpload = async () => {
//     if (files.length === 0) {
//       // Display a message asking to add a document first
//       Swal.fire({
//         icon: 'warning',
//         title: 'Add Document',
//         text: 'Please add a document first.',
//       });
//       return;
//     }

//     for (const file of files) {
//       const reader = new FileReader();
//       reader.onloadend = async () => {
//         const arrayBuffer = reader.result;
//         const pdf = await pdfjs.getDocument(arrayBuffer).promise;

//         const numPages = pdf.numPages;
//         setNumPages((prevNumPages) => [...prevNumPages, numPages]);

//         const metadata = await pdf.getMetadata();
//         const uniqueId = generateUniqueId();
//         const formattedDateTime = formatDateTime(metadata.info.ModDate);

//         const documentMetadata = {
//           ...metadata.info,
//           uniqueId,
//           formattedDateTime,
//         };

//         saveMetadataToLocal(documentMetadata, file);
//       };
//       reader.readAsArrayBuffer(file);
//     }
//     setShowMainContent(true); // Set showMainContent to true after file upload

//     // Display success message after file upload
//     Swal.fire({
//       icon: 'success',
//       title: 'Successful',
//       text: 'The documents has been submitted successfully.',
//     });
//   };

//   const generateUniqueId = () => {
//     return Math.random().toString(36).substr(2, 9);
//   };

//   const formatDateTime = (dateTime) => {
//     const dateRegex = /D:(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/;
//     const [, year, month, day, hour, minute, second] = dateRegex.exec(dateTime);

//     const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

//     return formattedDateTime;
//   };

//   const saveMetadataToLocal = async (metadata, file) => {
//     const storedMetadata = localStorage.getItem('pdfMetaData');
//     let allMetadata = storedMetadata ? JSON.parse(storedMetadata) : [];
//     allMetadata.push(metadata);
//     localStorage.setItem('pdfMetaData', JSON.stringify(allMetadata));
//     console.log(allMetadata)

//     // let storedFiles = localStorage.getItem('pdfFiles');
//     // let allFiles = storedFiles ? JSON.parse(storedFiles) : [];
//     // allFiles.push(file);
//     // localStorage.setItem('pdfFiles', allFiles);

//     setPdfMetaData((prevPdfMetaData) => [...prevPdfMetaData, metadata]);
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="user-dashboard">
//         <div className="sidebar">
//           <ul>
//             <li>
//               <button onClick={() => setShowMainContent(true)}>Add Document</button>
//             </li>
//             <li>
//               <button onClick={handleViewCertificate}>View Certificate</button>
//             </li>
//           </ul>
//         </div>
//         <div className="main-content">
//           {showMainContent ? (
//             <>
//               <input type="file" onChange={handleFileChange} multiple />
//               <button
//                 onClick={handleFileUpload}
//                 disabled={files.length === 0}
//                 className={files.length === 0 ? 'disabled' : ''}
//               >
//                 Upload files
//               </button>
//               {numPages.map((pages, index) => (
//                 <p key={index}>Number of pages for Document {index + 1}: {pages}</p>
//               ))}
//               {pdfMetaData.map((metadata, index) => (
//                 <div key={index}>
//                   <h3>Document{index + 1} Metadata:</h3>
//                   <p>Unique ID: {metadata.uniqueId}</p>
//                   <p>Creation Time: {metadata.formattedDateTime}</p>
//                   <pre>{JSON.stringify(metadata, null, 2)}</pre>
//                 </div>
//               ))}
//               {files.map((file, index) => (
//                 <div key={index}>
//                   <h3>Document{index + 1} Preview:</h3>
//                   <Document file={file}>
//                     <Page pageNumber={1} />
//                   </Document>
//                 </div>
//               ))}
//             </>
//           ) : (
//             <div className="blank-div">Click on "Add Document" in the sidebar to upload documents.</div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default DocumentMetadata;
