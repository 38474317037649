import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import studentPDF from '../../pdfs/EarthID - Company Accounts FY 22-23.pdf';
import transcriptPDF from '../../pdfs/EarthID - Business Bank Statement FY 22-23.pdf';
import ssiApi from '../../ssiApi.js';
import Navbar from '../Navbar/navbar';
import Footer from '../Footer/footer';
import './admin.css'

const MySwal = withReactContent(Swal);

const vcString = localStorage.getItem("verifiableCredential");

    if (vcString === null) {
      const newVCObject = {
        CreationDate: "D:20230414125909+04'00'",
        IsAcroFormPresent: false,
        IsCollectionPresent: false,
        IsLinearized: false,
        IsSignaturesPresent: false,
        IsXFAPresent: false,
        ModDate: "D:20230414125909+04'00'",
        PDFFormatVersion: "1.4",
        Producer: "mPDF 8.0.3",
        formattedDateTime: "2023-04-14 12:59:09",
        uniqueId: "idh91sjmi"
      };

      localStorage.setItem("verifiableCredential", JSON.stringify(newVCObject));
    }

    const vcArray = JSON.parse(localStorage.getItem("verifiableCredential"));
    const lastElement = vcArray[vcArray.length - 1];

const Admin = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [numPages, setNumPages] = useState(0);

  const users = [
    { id: 1, name: 'EarthID', pdfs: [transcriptPDF, studentPDF] },
    { id: 2, name: 'ServiceNow', pdfs: [transcriptPDF] },
    // Add more users with their respective PDFs
  ];

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleGenerateCertificate = async () => {
    if (selectedUser) {
      try {
        const loadingSwal = MySwal.fire({
          title: 'Generating Certificate',
          html: 'Please wait while the certificate is being generated...',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            MySwal.showLoading();
          },
        });
  
        await ssiApi.createVC(selectedUser);
  
        loadingSwal.close();
  
        console.log('Certificate generated successfully!');
        MySwal.fire({
          icon: 'success',
          title: 'Certificate Generated',
          text: 'Certificate generated successfully!',
          showCancelButton: true,
          confirmButtonText: 'View Certificate',
        }).then((result) => {
          if (result.isConfirmed) {
            const certificateUrl = `/certificate/${selectedUser.name}`; // Use selectedUser.name here
            const newTabWindow = window.open(certificateUrl, '_blank');
            newTabWindow.focus();
          }
        });
      } catch (error) {
        console.error('Error generating certificate:', error);
        MySwal.fire({
          icon: 'error',
          title: 'Certificate Generation Failed',
          text: 'Error generating certificate. Please try again.',
        });
      }
    }
  };

  const renderUserList = () => {
    return users.map((user) => (
      <div
        key={user.id}
        onClick={() => handleUserClick(user)}
        className={`user-item ${selectedUser === user ? 'selected' : ''}`}
      >
        {user.name}
      </div>
    ));
  };

  const renderPDFViewer = () => {
    if (!selectedUser) {
      return <p className="no-user">No entity selected.</p>;
    }

    return (
      <div className="pdf-viewer">
        <div className="pdf-header">
          <h3>Company: {selectedUser.name}</h3>
          <div className="action-buttons">
            <button className="issue-button" onClick={handleGenerateCertificate}>
              Issue Certificate
            </button>
            {/* <button className="deny-button">Deny</button> */}
          </div>
        </div>
        {selectedUser.pdfs.map((pdf, index) => (
          <div key={`pdf_${index}`} className="pdf-container">
            <h4>Document {index + 1}</h4>
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, pageIndex) => (
                <Page key={`page_${index + 1}_${pageIndex + 1}`} pageNumber={pageIndex + 1} width={800} />
              ))}
            </Document>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <div className="admin-container">
        <div className='row'>
        <div className="sidebar">{renderUserList()}</div>
        <div className="content">{renderPDFViewer()}</div>
        </div>
        
      </div>
      <Footer />
    </>
  );
};

export default Admin;