import 'bootstrap/dist/css/bootstrap.css';
import './navbar.css';
import { NavLink, useHistory, Redirect } from 'react-router-dom';
import logo from '../../img/kpmg-logo-1.jpg';

import React, { useState } from 'react';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const history = useHistory();

  const handleUserClick = () => {
    setIsLoggedIn(true);
    setIsAdmin(false);
    history.push('/uploadDoc'); // Replace '/user' with the desired user component path
  };

  const handleAdminClick = () => {
    setIsLoggedIn(true);
    setIsAdmin(true);
    history.push('/admin'); // Replace '/admin' with the desired admin component path
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    history.push('/');
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark shadow-5-strong mynav">
      <div className="container p-2">
        <NavLink className="navbar-brand" to="/">
          <figure>
            <img id="logo" height="50px" width="150px" src={logo} alt="logo" />
          </figure>
        </NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
          <ul className="navbar-nav text-center">
            <li className="nav-item active">
              <NavLink className="nav-link" to="/">
                Home <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                Services
              </NavLink>
            </li>
          </ul>
        </div>

        
          <div className="button-container">
            <button onClick={handleUserClick}>Customer</button>
            <button onClick={handleAdminClick}>Auditor</button>
          </div>
       
      </div>
    </nav>
  );
};

export default Navbar;





// import 'bootstrap/dist/css/bootstrap.css';
// import './navbar.css';
// import { NavLink, useHistory, Redirect } from 'react-router-dom';
// import logo from '../../img/kpmg-logo-1.jpg';

// import React, { useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
// import UploadPDF from "../UploadDoc/UploadPdf";
// import Admin from "../Admin/Admin";

// function Navbar() {
//   const [userId, setUserId] = useState('');
//   const [password, setPassword] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [isAdmin, setIsAdmin] = useState(false);
//   const history = useHistory();

//   useEffect(() => {
//     // Check if user is already logged in
//     const loggedInStatus = localStorage.getItem('isLoggedIn');
//     const adminStatus = localStorage.getItem('isAdmin');
//     if (loggedInStatus && adminStatus) {
//       setIsLoggedIn(loggedInStatus === 'true');
//       setIsAdmin(adminStatus === 'true');
//     }
//   }, []);

//   const handleUserLogin = () => {
//     Swal.fire({
//       title: 'User Login',
//       html:
//         `<input type="text" id="userId" class="swal2-input" placeholder="User ID" value="${userId}" autofocus>` +
//         `<input type="password" id="password" class="swal2-input" placeholder="Password" value="${password}">`,
//       showCancelButton: true,
//       confirmButtonText: 'Login',
//     }).then((result) => {
//       if (result.isConfirmed) {
//         const enteredUserId = document.getElementById('userId').value;
//         const enteredPassword = document.getElementById('password').value;

//         // Perform user login verification here
//         // You can compare enteredUserId and enteredPassword with your desired values

//         setIsLoggedIn(true);
//         setIsAdmin(false);
//         localStorage.setItem('isLoggedIn', true);
//         localStorage.setItem('isAdmin', false);
//         Swal.fire({
//           icon: 'success',
//           title: 'Login Successful!',
//         });
//       }
//     });
//   };

//   const handleAdminLogin = () => {
//     Swal.fire({
//       title: 'Admin Login',
//       html:
//         `<input type="text" id="userId" class="swal2-input" placeholder="User ID" value="${userId}" autofocus>` +
//         `<input type="password" id="password" class="swal2-input" placeholder="Password" value="${password}">`,
//       showCancelButton: true,
//       confirmButtonText: 'Login',
//     }).then((result) => {
//       if (result.isConfirmed) {
//         const enteredUserId = document.getElementById('userId').value;
//         const enteredPassword = document.getElementById('password').value;

//         // Perform admin login verification here
//         // You can compare enteredUserId and enteredPassword with your desired values

//         setIsAdmin(true);
//         setIsLoggedIn(true);
//         localStorage.setItem('isLoggedIn', true);
//         localStorage.setItem('isAdmin', true);
//         Swal.fire({
//           icon: 'success',
//           title: 'Login Successful!',
//         });
//       }
//     });
//   };

//   const handleLogout = () => {
//     setIsLoggedIn(false);
//     setIsAdmin(false);
//     localStorage.setItem('isLoggedIn', false);
//     localStorage.setItem('isAdmin', false);
//     history.push('/');
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-dark shadow-5-strong mynav">
//       <div className="container p-2">
//         <NavLink className="navbar-brand" to="/">
//           <figure>
//             <img id="logo" height="50px" width="150px" src={logo} alt="logo" />
//           </figure>
//         </NavLink>
//         <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//           <span className="navbar-toggler-icon"></span>
//         </button>

//         <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
//           <ul className="navbar-nav text-center">
//             <li className="nav-item active">
//               <NavLink className="nav-link" to="/">
//                 Home <span className="sr-only">(current)</span>
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink className="nav-link" to="/">
//                 Services
//               </NavLink>
//             </li>
//           </ul>
//         </div>

//         {isLoggedIn ? (
//           <>
//             {isAdmin ? (
//               <>
//                 <Redirect to="/admin" />
//                 <button className="button-container1" onClick={handleLogout}>Logout</button>
//               </>
//             ) : (
//               <>
//                 <Redirect to="/uploadDoc" />
//                 <button className="button-container1" onClick={handleLogout}>Logout</button>
//               </>
//             )}
//           </>
//         ) : (
//           <div className="button-container">
//             <button onClick={handleUserLogin}>User Login</button>
//             <button onClick={handleAdminLogin}>Admin Login</button>
//           </div>
//         )}
//       </div>
//     </nav>
//   );
// }

// export default Navbar;
